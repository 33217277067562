import { render, staticRenderFns } from "./replyProblem.vue?vue&type=template&id=acabf5fc&scoped=true&"
import script from "./replyProblem.vue?vue&type=script&lang=js&"
export * from "./replyProblem.vue?vue&type=script&lang=js&"
import style0 from "./replyProblem.vue?vue&type=style&index=0&id=acabf5fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acabf5fc",
  null
  
)

export default component.exports